<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.flights')} / ${$t('lbl.frecuency')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'flights-contrate-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <!--<v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>-->
          </v-row>
          <v-form class="multi-col-validation">
            <!--SELECCION DEL VUELO -->
            <v-row v-if="idVuelo === null">
              <v-col
                cols="12"
                md="3"
              >
                <strong>{{ $t('lbl.selectVuelo') }}</strong>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    <div class="d-block mb-2">
                      <div class="d-flex justify-start align-center">
                        <p class="mb-1 mt-2">{{ $t('lbl.contrateInsert') }}</p>
                      </div>
                    </div>
                  </span>
                </v-tooltip>
                <!--<v-select
                  v-model="model.aerolinea_id"
                  class="pt-2"
                  :items="aerolineas"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.operador')"
                  outlined
                  dense
                  hide-details
                  @change="changeAerolinea"
                ></v-select>-->
                <v-autocomplete
                  v-model="model.aerolinea_id"
                  class="pt-2"
                  :items="itemsAerolinea"
                  :search-input.sync="searchAerolinea"
                  hide-details
                  hide-selected
                  :label="$t('lbl.operador')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  single-line
                  @change="changeAerolinea"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.operador') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <strong style="color: #FFFFFF">.</strong>
                <v-autocomplete
                  ref="numero_de_vuelo"
                  v-model="model.numero_de_vuelo"
                  class="pt-2"
                  :items="flights"
                  :search-input.sync="searchFlight"
                  hide-details
                  hide-selected
                  :label="$t('lbl.numeroVuelo')"
                  outlined
                  dense
                  @change="changeVuelo"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.numeroVuelo') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon left>
                      {{ icons.mdiAirplaneTakeoff }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <strong style="opacity:0;">.</strong>
                <v-autocomplete
                  v-model="model.proveedor_id"
                  class="pt-2"
                  :items="itemsProveedor"
                  :search-input.sync="searchProveedor"
                  hide-details
                  hide-selected
                  :label="$t('lbl.proveedor')"
                  outlined
                  dense
                  item-text="name_comercial"
                  item-value="id"
                  @change="setProveedor()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.proveedor') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name_comercial"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <strong style="opacity:0;">.</strong>
                <v-text-field
                  v-model="model.identificador"
                  :label="$t('lbl.identificador')"
                  class="pt-2"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <!--DETALLES DEL VUELO SELECCIONADO-->
            <v-row v-if="flightDetail !== null">
              <v-col
                cols="12"
                md="12"
              >
                <v-card class="my-4">
                  <v-card-title
                    primary-title
                    class="d-flex align-baseline justify-start"
                  >
                    {{ $t('lbl.flight') }}:<span
                      class="px-2"
                      style="font-size:16px"
                    >{{
                      flightDetail.numero_de_vuelo
                    }}</span>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <div class="d-flex justify-space-between align-center">
                          <p class="mb-0 font-weight-bold">
                            {{ flightDetail.city_origen }}
                          </p>
                          <p class="mb-0 font-weight-bold d-flex justify-end">
                            {{ flightDetail.city_destino }}
                          </p>
                        </div>
                        <div class="d-flex justify-space-between align-center">
                          <div class="d-block justify-start">
                            <p
                              class="mb-0 font-weight-bold"
                              style="font-size: 35px;"
                            >
                              {{ flightDetail.iata_origen }}
                            </p>
                            <p class="mb-0">
                              <!--3:29pm-->
                            </p>
                          </div>
                          <div>
                            <div class="d-flex justify-center align-center pt-4">
                              <v-divider width="80px"></v-divider>
                              <v-icon
                                class="d-flex justify-center align-center mx-6"
                                medium
                                color="gray"
                              >
                                {{ icons.mdiAirplaneTakeoff }}
                              </v-icon>
                              <v-divider width="80px"></v-divider>
                            </div>
                            <p
                              class="mb-0 d-flex justify-center"
                              style="font-size:14px"
                            >
                              <!--Duracion: 2h 46m-->
                            </p>
                          </div>
                          <div class="d-block justify-end">
                            <p
                              class="mb-0 font-weight-bold d-flex justify-end"
                              style="font-size: 35px;"
                            >
                              {{ flightDetail.iata_destino }}
                            </p>
                            <p class="mb-0 d-flex justify-end ">
                              <!--5:20pm-->
                            </p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <div class="d-block align-baseline justify-end pr-10">
                          <div>
                            <v-row>
                              <v-col
                                cols="12"
                                md="6"
                              ></v-col>
                              <v-col
                                cols="12"
                                md="6"
                                class="d-flex justify-end"
                              >
                                <v-img
                                  :src="rutaPublic + airline.logo"
                                  :alt="airline.slug"
                                  width="60"
                                  max-height="150"
                                  contain
                                ></v-img>
                              </v-col>
                            </v-row>
                          </div>
                          <p class="mb-0 font-weight-bold d-flex justify-end">
                            {{ $t('lbl.operador') }}:
                          </p>
                          <p
                            style="font-size:13px"
                            class="mb-0 d-flex justify-end"
                          >
                            {{ airline.name }}
                          </p>
                          <p
                            style="font-size:13px"
                            class="mb-0 d-flex justify-end"
                          >
                            {{ flightDetail.aeronave.modelo }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <!--<v-card class="my-4">
                  <v-card-title
                    primary-title
                    class="d-flex align-baseline justify-start"
                  >
                    {{ $t('lbl.flight') }}:<span
                      class="px-2"
                      style="font-size:16px"
                    >{{ flightDetail.numero_de_vuelo }}</span>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <div class="rb-container ml-10">
                          <ul class="rb">
                            <li class="rb-item">
                              <v-icon
                                color="white"
                                style="position:absolute; top:2px; left:-12px"
                              >
                                {{ icons.mdiAirplaneTakeoff }}
                              </v-icon>
                              <p class="mb-0 font-weight-bold">
                                {{ flightDetail.origen.destino.iataComodin }}
                              </p>
                            </li>
                            <li class="rb-item">
                              <v-icon
                                color="white"
                                style="position:absolute; top:2px; left:-12px"
                              >
                                {{ icons.mdiAirplaneLanding }}
                              </v-icon>
                              <p class="mb-0 font-weight-bold">
                                {{ flightDetail.destino.destino.iataComodin }}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <div class="d-block align-baseline justify-end pr-10">
                          <div class="d-flex justify-end">

                            <img
                              :src="rutaPublic+airline.logo"
                              height="80"
                            />

                          </div>
                          <p class="mb-0 font-weight-bold d-flex justify-end">
                            {{ $t('lbl.operador') }}:
                          </p>
                          <p
                            style="font-size:13px"
                            class="mb-0 d-flex justify-end"
                          >
                            {{ airline.name }}
                          </p>
                          <p
                            style="font-size:13px"
                            class="mb-0 d-flex justify-end"
                          >
                            {{ flightDetail.aeronave.modelo }}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>-->
              </v-col>

              <fragment v-if="frecuenciaFlight.length > 0">
                <v-col
                  cols="12"
                  md="12"
                  class="pt-5"
                >
                  <strong>
                    {{ $t('lbl.frecuenciasContrate') }}
                  </strong>
                </v-col>
                <v-col
                  v-for="(frecuencia, index) in frecuenciaFlight"
                  :key="index"
                  cols="12"
                  md="12"
                >
                  <v-chip
                    class="ma-2"
                    :color="indexFrecuenciaContrate === frecuencia.code_tarifa ? 'primary' : 'error'"
                    @click="setIndexFrecuenciaContrate(frecuencia.code_tarifa)"
                  >
                    {{ frecuencia.code_tarifa }},
                    {{ $t('lbl.vigenteAl', { from: frecuencia.from_validez, to: frecuencia.to_validez }) }}
                  </v-chip>
                </v-col>

                <v-col
                  v-if="indexFrecuenciaContrate !== null"
                  cols="12"
                  md="12"
                  class="pt-5"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <strong>{{ $t('lbl.programationFlights') }}</strong>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <!--
                        :items="clases"
                        item-text="name"
                        item-value="id"
                        @change="changeClase"
                      -->
                      <v-select
                        v-model="claseTarifa"
                        :disabled="$store.state.app.onlyShow"
                        :label="$t('lbl.tarifa')"
                        :items="clasesTarifaContrate"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        hide-details
                        @change="changeClase"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  v-if="indexFrecuenciaContrate !== null"
                  cols="12"
                  md="12"
                >
                  <v-row class="fill-height">
                    <v-col>
                      <v-sheet height="64">
                        <v-toolbar flat>
                          <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                          >
                            <v-icon small>
                              mdi-chevron-left
                            </v-icon>
                          </v-btn>
                          <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                          >
                            <v-icon small>
                              mdi-chevron-right
                            </v-icon>
                          </v-btn>
                          <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                          </v-toolbar-title>
                          <!--<v-spacer></v-spacer>
                          <v-menu
                            bottom
                            right
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span>{{ typeToLabel[type] }}</span>
                                <span>{{ $t('lbl.month') }}</span>
                                <v-icon right>
                                  mdi-menu-down
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="type = 'day'">
                                <v-list-item-title>Day</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="type = 'week'">
                                <v-list-item-title>Week</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="type = 'month'">
                                <v-list-item-title>{{ $t('lbl.month') }}</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="type = '4day'">
                                <v-list-item-title>4 days</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>-->
                        </v-toolbar>
                      </v-sheet>
                      <v-sheet height="600">
                        <v-calendar
                          ref="calendar"
                          v-model="focus"
                          color="primary"
                          :events="events"
                          :type="type"
                          locale="es"
                          event-name="symbol"
                          @click:date="viewDay"
                        >
                          <!--@click:event="showEvent"
                          @click:more="viewDay"-->
                        </v-calendar>
                        <!--<v-menu
                          v-model="selectedOpen"
                          :close-on-content-click="false"
                          :activator="selectedElement"
                          offset-x
                        >
                          <v-card
                            v-if="selectedEvent.edit && selectedEvent.type === 'price'"
                            color="grey lighten-4"
                            min-width="350px"
                            flat
                          >
                            <v-toolbar
                              color="primary"
                              dark
                            >
                              <v-toolbar-title>
                                {{ $t('lbl.changePrice') }}
                              </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                              <v-text-field
                                v-model="selectedEvent.name"
                                type="number"
                                prefix="$"
                              >
                              </v-text-field>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                text
                                color="primary"
                                @click="selectedOpen = false"
                              >
                                Enviar
                              </v-btn>
                              <v-btn
                                text
                                color="error"
                                @click="selectedOpen = false"
                              >
                                Cancel
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                          <v-card
                            v-if="selectedEvent.edit && selectedEvent.type === 'seat'"
                            color="grey lighten-4"
                            min-width="350px"
                            flat
                          >
                            <v-toolbar
                              color="primary"
                              dark
                            >
                              <v-toolbar-title>
                                {{ $t('lbl.changeSeat') }}
                              </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                              <v-text-field
                                v-model="selectedEvent.name"
                                type="number"
                                suffix="asientos"
                              >
                              </v-text-field>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                text
                                color="primary"
                                @click="selectedOpen = false"
                              >
                                Enviar
                              </v-btn>
                              <v-btn
                                text
                                color="error"
                                @click="selectedOpen = false"
                              >
                                Cancel
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>-->
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </fragment>

              <v-col
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-row>
                      <!--FRECENCIAS DEL VUELO-->
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <b>{{ $t('lbl.frecuenciasFlight') }}</b>
                        <v-radio-group
                          v-model="radioFrecuencia"
                          :disabled="$store.state.app.onlyShow"
                        >
                          <v-radio
                            v-for="(frecuencia, index) in flightDetail.frecuencia"
                            :key="index"
                            :label="
                              frecuencia.frecuencia.length === 7
                                ? $t('lbl.todosDays')
                                : `${nameFrecuencia(frecuencia.frecuencia)} vigente del ${frecuencia.fromValidez} al ${
                                  frecuencia.toValidez
                                }`
                            "
                            :value="index"
                            @click="setRadioFrecuency(frecuencia, index)"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <b>{{ $t('lbl.fechasVuelo') }}</b>
                        <v-radio-group
                          v-model="radioGroup"
                          :disabled="$store.state.app.onlyShow"
                        >
                          <v-radio
                            :label="$t('lbl.todosDay')"
                            hide-details
                            class="mt-0 pt-0"
                            @click="resetRadios"
                          ></v-radio>
                          <div class="d-block justify-start py-2">
                            <div class="d-flex justify-start">
                              <v-radio
                                :label="$t('lbl.semanaDay')"
                                class="pr-3 mt-0 pt-0"
                                hide-details
                                @click="resetRadios"
                              ></v-radio>
                            </div>
                            <div class="pl-4">
                              <v-sheet class="py-2">
                                <v-slide-group
                                  v-model="modelSemanas"
                                  multiple
                                >
                                  <v-slide-item
                                    v-for="(item, index) in semanas"
                                    v-slot="{ active, toggle }"
                                    :key="index"
                                    class="px-1"
                                  >
                                    <span @click="editDates">
                                      <v-btn
                                        :disabled="!allowedFrecuencia.includes(index) || $store.state.app.onlyShow"
                                        class=""
                                        :input-value="active"
                                        active-class="primary white--text"
                                        depressed
                                        fab
                                        small
                                        @click="toggle"
                                      >
                                        {{ item }}
                                      </v-btn>
                                    </span>
                                  </v-slide-item>
                                </v-slide-group>
                              </v-sheet>
                            </div>
                          </div>

                          <v-radio
                            :label="$t('lbl.especifDay')"
                            class="py-2 mt-0 pt-0"
                            hide-details
                            @click="resetRadios"
                          ></v-radio>
                          <template>
                            <v-row>
                              <v-col cols="8">
                                <v-date-picker
                                  v-model="dates"
                                  :first-day-of-week="0"
                                  :allowed-dates="allowedDates"
                                  no-title
                                  :show-current="false"
                                  full-width
                                  multiple
                                  locale="es"
                                  :min="flightDetail.fromValidez"
                                  :max="flightDetail.toValidez"
                                ></v-date-picker>
                              </v-col>
                            </v-row>
                          </template>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                v-if="radioFrecuencia !== -1 && !$store.state.app.onlyShow"
                cols="12"
                md="2"
              >
                <v-btn
                  color="primary"
                  class="mb-4 me-3"
                  :loading="loading"
                  @click="save()"
                >
                  <span>{{ btnTitle }}</span>
                  <v-icon>{{ icons.mdiUpdate }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <!--SUPLEMENTOS-->
            <v-card-text>
              <v-row>
                <v-col
                  v-if="flightDetail !== null"
                  cols="12"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="text-uppercase"
                            width="10%"
                          >
                            {{ $t('lbl.active') }}
                          </th>
                          <th class="text-uppercase">
                            {{ $t('lbl.suplemento') }}
                          </th>
                          <th class="text-uppercase">
                            {{ $t('lbl.modalidad') }}
                          </th>
                          <th
                            class="text-uppercase"
                            width="10%"
                          >
                            {{ $t('lbl.price') }}
                          </th>
                          <th
                            class="text-uppercase"
                            colspan="2"
                            width="25%"
                          >
                            {{ $t('lbl.periodoValidez') }}
                          </th>
                          <th
                            class="text-uppercase"
                            width="10%"
                          >
                            ONLINE
                          </th>
                          <th
                            class="text-uppercase"
                            width="10%"
                          >
                            {{ $t('lbl.obligatorio') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <fragment
                          v-for="(suplemento, indSuplemento) in suplementos"
                          :key="indSuplemento"
                        >
                          <Suplementos
                            :suplemento="suplemento"
                            :ind-suplemento="indSuplemento"
                          />
                        </fragment>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>

            <!--CONDITIONES GENERALES-->
            <v-card-text v-if="categCondicionesGenerales.length > 0 && flightDetail !== null">
              <h3>{{ $t('menu.conditionGenerals') }}</h3>
              <br />
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(cate, ind) in categCondicionesGenerales"
                  :key="ind"
                >
                  <v-expansion-panel-header>
                    {{ cate.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <fragment
                      v-for="(cond, indCond) in condicionesGenerales"
                      :key="indCond"
                    >
                      <fragment v-if="cate.id === cond.category_id">
                        <!--
                          :label="cond.description"
                        -->
                        <v-checkbox
                          v-model="condicionesGenerales_id"
                          :disabled="$store.state.app.onlyShow"
                          :value="cond.id"
                          hide-details
                          class="pt-0"
                        >
                          <template v-slot:label>
                            <span
                              class="mt-1"
                              v-html="cond.description"
                            ></span>
                          </template>
                        </v-checkbox>
                      </fragment>
                    </fragment>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>

            <!--DETALLES DEL CONTRATO-->
            <!--POLITICA TARIFARIA-->
            <v-row
              v-if="flightDetail !== null"
              no-gutters
            >
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
                <b>{{ $t('menu.politicaTarifa') }}</b>
                <v-radio-group
                  v-model="model.politica_tarifaria_id"
                  :disabled="$store.state.app.onlyShow"
                >
                  <v-radio
                    v-for="(politica, index) in politicasTarifarias"
                    :key="index"
                    :label="politica.name"
                    :value="politica.id"
                    @click="setPoliticaTarifaria"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                v-if="model.politica_tarifaria_id"
                style="overflow-x:auto"
                cols="12"
                :sm="
                  $store.state.app.clasesVuelos.length === 1 ? 6 : $store.state.app.clasesVuelos.length === 2 ? 9 : 12
                "
                :md="
                  $store.state.app.clasesVuelos.length === 1 ? 6 : $store.state.app.clasesVuelos.length === 2 ? 9 : 12
                "
              >
                <v-simple-table v-if="radioFrecuencia !== -1">
                  <template v-slot:default>
                    <tbody>
                      <fragment>
                        <tr>
                          <td
                            class="text-left"
                            :colspan="$store.state.app.clasesVuelos.length + 1"
                          >
                            <v-row no-gutters>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                <h3>{{ $t('lbl.detailContrate') }}</h3>
                              </v-col>
                            </v-row>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="text-left"
                            width="200px"
                          >
                            <v-row no-gutters>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                {{ $t('lbl.clase') }}
                              </v-col>
                            </v-row>
                          </td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                            class="text-left"
                          >
                            <SelectClaseVuelo
                              :clase="clase"
                              :pos="i"
                              :airline="airline"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ $t('lbl.tarifa') }}
                          </td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                          >
                            <SelectTarifaVuelo
                              v-if="!$store.state.app.loadTarifasVuelos"
                              :clase="clase"
                              :airline="airline"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ $t('lbl.cupo') }}
                          </td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                          >
                            <CupoClaseVuelo :clase="clase" />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('lbl.price_adult') }}</td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                          >
                            <PriceAdultClaseVuelo :clase="clase" />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('lbl.price_child') }}</td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                          >
                            <PriceChildClaseVuelo :clase="clase" />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('lbl.price_infant') }}</td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                          >
                            <PriceInfClaseVuelo :clase="clase" />
                          </td>
                        </tr>

                        <tr>
                          <td
                            style="height: 80px"
                            :colspan="$store.state.app.clasesVuelos.length + 1"
                          >
                            <h3>{{ $t('lbl.conditionContrate') }}</h3>
                          </td>
                        </tr>
                      </fragment>

                      <!--EQUIPAJE PERSONAL-->
                      <tr>
                        <td>
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="10"
                              md="10"
                            >
                              <b>{{ $t('lbl.equipaje_personal.title') }}</b>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                x-small
                                color="primary"
                                @click="hidePersonal = !hidePersonal"
                              >
                                <v-icon>
                                  {{ hidePersonal ? icons.mdiMenuUp : icons.mdiMenuDown }}
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                      <tr v-if="hidePersonal">
                        <td>{{ $t('lbl.equipaje_personal.dimensiones') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectDimensionPersonalVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hidePersonal">
                        <td>{{ $t('lbl.equipaje_personal.peso') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectPesoPersonalVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hidePersonal">
                        <td>{{ $t('lbl.equipaje_personal.precio') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <PricePersonalVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hidePersonal">
                        <td>{{ $t('lbl.equipaje_personal.para') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <AplicaPersonalVuelo :clase="clase" />
                        </td>
                      </tr>

                      <!--EQUIPAJE DE MANO-->
                      <tr>
                        <td>
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="10"
                              md="10"
                            >
                              <b>{{ $t('lbl.equipaje_cabina.title') }}</b>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                x-small
                                color="primary"
                                @click="hideCabina = !hideCabina"
                              >
                                <v-icon>
                                  {{ hideCabina ? icons.mdiMenuUp : icons.mdiMenuDown }}
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                      <tr v-if="hideCabina">
                        <td>{{ $t('lbl.equipaje_cabina.dimensiones') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectDimensionCabinaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideCabina">
                        <td>{{ $t('lbl.equipaje_cabina.peso') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectPesoCabinaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideCabina">
                        <td>{{ $t('lbl.equipaje_cabina.precio') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <PriceCabinaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideCabina">
                        <td>{{ $t('lbl.equipaje_cabina.para') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <AplicaCabinaVuelo :clase="clase" />
                        </td>
                      </tr>

                      <!--EQUIPAJE BODEGA-->
                      <tr>
                        <td>
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              sm="10"
                              md="10"
                            >
                              <b>{{ $t('lbl.equipaje_bodega.title') }}</b>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="2"
                              md="2"
                            >
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                x-small
                                color="primary"
                                @click="hideBodega = !hideBodega"
                              >
                                <v-icon>
                                  {{ hideBodega ? icons.mdiMenuUp : icons.mdiMenuDown }}
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                      <tr v-if="hideBodega">
                        <td>{{ $t('lbl.equipaje_bodega.dimensiones') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectDimensionBodegaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideBodega">
                        <td>{{ $t('lbl.equipaje_bodega.peso') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <SelectPesoBodegaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <template v-if="hideBodega">
                        <tr
                          v-for="(maleta, j) in $store.state.app.clasesVuelos[0].equipaje_bodega.maletas"
                          :key="j"
                        >
                          <td>
                            <v-row no-gutters>
                              <v-col
                                cols="12"
                                md="12"
                                sm="12"
                              >
                                {{ `${$t('lbl.equipaje_bodega.precioMaleta')} ${j + 1}` }}
                              </v-col>
                            </v-row>
                          </td>
                          <td
                            v-for="(clase, i) in $store.state.app.clasesVuelos"
                            :key="i"
                          >
                            <PriceMaletaBodegaVuelo
                              :clase="clase"
                              :pos="j"
                            />
                          </td>
                        </tr>
                      </template>
                      <tr v-if="hideBodega && $store.state.app.clasesVuelos.length > 0">
                        <fragment>
                          <td :colspan="$store.state.app.clasesVuelos.length + 1">
                            <v-row no-gutters>
                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                class="pt-2"
                              >
                                <b>{{ $t('lbl.equipaje_bodega.exceso_equipaje') }}</b>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="3"
                                md="3"
                              >
                                <div class="d-flex">
                                  <b class="ml-10 pt-2">{{ $t('lbl.sobrepeso') }}</b>
                                  <v-switch
                                    v-model="$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_peso"
                                    :label="`$/${$store.state.app.clasesVuelos[0].equipaje_bodega.um_peso}`"
                                    dense
                                    hide-details
                                    class="mb-0 mt-2 ml-2"
                                    disabled
                                  ></v-switch>
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="3"
                                md="3"
                              >
                                <div class="d-flex">
                                  <b class="ml-10 pt-2 mr-1">{{ $t('lbl.equipaje_bodega.max_exceso_equipaje') }}</b>
                                  <v-tooltip
                                    top
                                    color="white"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        :color="$vuetify.theme.themes.light.primary"
                                        v-on="on"
                                      >
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </template>
                                    <span>
                                      <div class="d-block rounded-lg rounded-xl">
                                        <div class="justify-start align-center rounded-lg rounded-xl">
                                          <p
                                            class="text-justify"
                                            :style="`color: ${$vuetify.theme.themes.light.primary}`"
                                          >
                                            {{ $t('msg.agreguePesoMax1') }}<br />
                                            {{ $t('msg.agreguePesoMax2') }}
                                          </p>
                                        </div>
                                      </div>
                                    </span>
                                  </v-tooltip>
                                  <v-text-field
                                    v-model="$store.state.app.clasesVuelos[0].equipaje_bodega.max_exceso_equipaje"
                                    type="number"
                                    min="0"
                                    hide-details
                                    dense
                                    outlined
                                    :suffix="$store.state.app.clasesVuelos[0].equipaje_bodega.um_peso"
                                    class="col-4"
                                    disabled
                                  ></v-text-field>
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="4"
                                md="4"
                              >
                                <div class="d-flex">
                                  <v-icon color="error">
                                    {{ icons.mdiAlertOutline }}
                                  </v-icon>
                                  <span class="ml-1 pt-2">{{ $t('msg.equipajeSupere') }}</span>
                                </div>
                              </v-col>
                            </v-row>
                          </td>
                        </fragment>
                      </tr>
                      <template v-if="hideBodega && $store.state.app.clasesVuelos.length > 0">
                        <fragment v-if="$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_peso">
                          <tr
                            v-for="(exceso_equip, j) in $store.state.app.clasesVuelos[0].equipaje_bodega
                              .exceso_equipaje"
                            :key="j"
                          >
                            <td>
                              <v-row no-gutters>
                                <v-col
                                  cols="12"
                                  md="10"
                                  sm="10"
                                >
                                  <RangeExcesoEquipajeBodegaVuelo
                                    :key="Math.random() * (j + 1)"
                                    :pos="j"
                                  />
                                </v-col>
                              </v-row>
                            </td>
                            <td
                              v-for="(clase, i) in $store.state.app.clasesVuelos"
                              :key="i"
                            >
                              <PriceExcesoEquipajeBodegaVuelo
                                :clase="clase"
                                :pos="j"
                              />
                            </td>
                          </tr>
                        </fragment>
                      </template>

                      <!--DIMENSION-->
                      <tr v-if="hideBodega && $store.state.app.clasesVuelos.length > 0">
                        <fragment>
                          <td :colspan="$store.state.app.clasesVuelos.length + 1">
                            <v-row no-gutters>
                              <v-col
                                cols="12"
                                sm="2"
                                md="2"
                                class="pt-2"
                              >
                              </v-col>
                              <v-col
                                cols="12"
                                sm="3"
                                md="3"
                              >
                                <div class="d-flex">
                                  <b class="ml-10 pt-2">{{ $t('lbl.sobreDimension') }}</b>
                                  <v-switch
                                    v-model="$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_dimension"
                                    :label="`$/${$store.state.app.clasesVuelos[0].equipaje_bodega.um_dimension}`"
                                    dense
                                    hide-details
                                    class="mb-0 mt-2 ml-2"
                                    disabled
                                  ></v-switch>
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="3"
                                md="3"
                              >
                                <div class="d-flex">
                                  <b class="ml-10 pt-2 mr-1">{{ $t('lbl.dimensionMax') }}</b>
                                  <v-tooltip
                                    top
                                    color="white"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        v-bind="attrs"
                                        :color="$vuetify.theme.themes.light.primary"
                                        v-on="on"
                                      >
                                        {{ icons.mdiInformationOutline }}
                                      </v-icon>
                                    </template>
                                    <span>
                                      <div class="d-block rounded-lg rounded-xl">
                                        <div class="justify-start align-center rounded-lg rounded-xl">
                                          <p
                                            class="text-justify"
                                            :style="`color: ${$vuetify.theme.themes.light.primary}`"
                                          >
                                            {{ $t('msg.agreguePesoMax1') }}<br />
                                            {{ $t('msg.agreguePesoMax2') }}
                                          </p>
                                        </div>
                                      </div>
                                    </span>
                                  </v-tooltip>
                                  <v-text-field
                                    v-model="$store.state.app.clasesVuelos[0].equipaje_bodega.max_exceso_dimension"
                                    type="number"
                                    min="0"
                                    hide-details
                                    dense
                                    outlined
                                    :suffix="$store.state.app.clasesVuelos[0].equipaje_bodega.um_dimension"
                                    class="col-4"
                                    disabled
                                  ></v-text-field>
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="4"
                                md="4"
                              >
                                <div class="d-flex">
                                  <v-icon color="error">
                                    {{ icons.mdiAlertOutline }}
                                  </v-icon>
                                  <span class="ml-1 pt-2">{{ $t('msg.equipajeSupere') }}</span>
                                </div>
                              </v-col>
                            </v-row>
                          </td>
                        </fragment>
                      </tr>
                      <template v-if="hideBodega && $store.state.app.clasesVuelos.length > 0">
                        <fragment v-if="$store.state.app.clasesVuelos[0].equipaje_bodega.sobre_dimension">
                          <tr
                            v-for="(exceso_dimen, j) in $store.state.app.clasesVuelos[0].equipaje_bodega
                              .exceso_dimension"
                            :key="j"
                          >
                            <td>
                              <v-row no-gutters>
                                <v-col
                                  cols="12"
                                  md="10"
                                  sm="10"
                                >
                                  <RangeExcesoDimensionBodegaVuelo
                                    :key="Math.random() * (j + 1)"
                                    :pos="j"
                                  />
                                </v-col>
                              </v-row>
                            </td>
                            <td
                              v-for="(clase, i) in $store.state.app.clasesVuelos"
                              :key="i"
                            >
                              <PriceExcesoDimensionBodegaVuelo
                                :clase="clase"
                                :pos="j"
                              />
                            </td>
                          </tr>
                        </fragment>
                      </template>

                      <tr v-if="hideBodega">
                        <td>{{ $t('lbl.equipaje_bodega.tasa_despacho') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <TasaDespachoBodegaVuelo :clase="clase" />
                        </td>
                      </tr>
                      <tr v-if="hideBodega">
                        <td>{{ $t('lbl.equipaje_bodega.para') }}</td>
                        <td
                          v-for="(clase, i) in $store.state.app.clasesVuelos"
                          :key="i"
                        >
                          <AplicaBodegaVuelo :clase="clase" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <!--<v-col
                cols="12"
                sm="12"
                md="12"
                class="pb-2 pt-3"
              >
                {{ $t('lbl.detailContrate') }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="adicionarClaseVuelo()"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.insert') }}</span>
                </v-tooltip>
              </v-col>

              <v-col
                v-if="!$store.state.app.loadClasesVuelos"
                cols="12"
                sm="12"
                md="12"
              >
                <ClaseVuelo
                  v-for="(clase, i) in $store.state.app.clasesVuelos"
                  :key="i"
                  :clase="clase"
                />
              </v-col>-->
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- FORM EDITAR CUPOS -->
    <v-dialog
      v-model="isDialogVisibleCupos"
      scrollable
      max-width="500px"
    >
      <v-card max-height="450px">
        <v-card-title>{{ $t('lbl.editCupos', { date: dateCalendar }) }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row v-if="tarifasEdit.length === 0">
            <v-col
              cols="12"
              md="12"
            >
              <v-alert
                color="primary"
                border="top"
                colored-border
                type="info"
                elevation="2"
              >
                {{ $t('msg.noElementShow') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            v-for="(tari, i) in tarifasEdit"
            :key="i"
          >
            <v-col
              cols="12"
              md="12"
            >
              <strong>{{ $t('lbl.clase') }}:</strong> {{ tari.clase }} <strong>{{ $t('lbl.tarif') }}:</strong>
              {{ tari.tarifa }}
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="tari.cupo"
                :disabled="$store.state.app.onlyShow"
                :label="$t('lbl.cupo')"
                outlined
                dense
                hide-details
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="tari.price_adult"
                :disabled="$store.state.app.onlyShow"
                :label="$t('lbl.price_adult')"
                outlined
                dense
                hide-details
                type="number"
                min="0"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="tari.price_child"
                :disabled="$store.state.app.onlyShow"
                :label="$t('lbl.price_child')"
                outlined
                dense
                hide-details
                type="number"
                min="0"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="tari.price_infant"
                :disabled="$store.state.app.onlyShow"
                :label="$t('lbl.price_infant')"
                outlined
                dense
                hide-details
                type="number"
                min="0"
                prefix="$"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleCupos = !isDialogVisibleCupos"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            :loading="loading"
            :disabled="$store.state.app.onlyShow"
            @click="updateCupos()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiAirplaneLanding,
  mdiCity,
  mdiChevronUpCircle,
  mdiMenuUp,
  mdiChevronDownCircle,
  mdiMenuDown,
  mdiAirplane,
  mdiInformation,
  mdiChevronLeft,
  mdiChevronRight,
  mdiInformationOutline,
  mdiAlertOutline,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

// import ClaseVuelo from '../utils/ClaseVuelo'
import SelectClaseVuelo from '../utils/tarifas/SelectClaseVuelo.vue'
import SelectTarifaVuelo from '../utils/tarifas/SelectTarifaVuelo.vue'
import CupoClaseVuelo from '../utils/tarifas/CupoClaseVuelo.vue'
import PriceAdultClaseVuelo from '../utils/tarifas/PriceAdultClaseVuelo.vue'
import PriceChildClaseVuelo from '../utils/tarifas/PriceChildClaseVuelo.vue'
import PriceInfClaseVuelo from '../utils/tarifas/PriceInfClaseVuelo.vue'
import SelectDimensionPersonalVuelo from '../utils/tarifas/SelectDimensionPersonalVuelo.vue'
import SelectDimensionCabinaVuelo from '../utils/tarifas/SelectDimensionCabinaVuelo.vue'
import SelectPesoCabinaVuelo from '../utils/tarifas/SelectPesoCabinaVuelo.vue'
import SelectPesoPersonalVuelo from '../utils/tarifas/SelectPesoPersonalVuelo.vue'
import PricePersonalVuelo from '../utils/tarifas/PricePersonalVuelo.vue'
import PriceCabinaVuelo from '../utils/tarifas/PriceCabinaVuelo.vue'
import AplicaPersonalVuelo from '../utils/tarifas/AplicaPersonalVuelo.vue'
import AplicaCabinaVuelo from '../utils/tarifas/AplicaCabinaVuelo.vue'
import SelectDimensionBodegaVuelo from '../utils/tarifas/SelectDimensionBodegaVuelo.vue'
import SelectPesoBodegaVuelo from '../utils/tarifas/SelectPesoBodegaVuelo.vue'
import PriceMaletaBodegaVuelo from '../utils/tarifas/PriceMaletaBodegaVuelo.vue'
import RangeExcesoEquipajeBodegaVuelo from '../utils/tarifas/RangeExcesoEquipajeBodegaVuelo.vue'
import PriceExcesoEquipajeBodegaVuelo from '../utils/tarifas/PriceExcesoEquipajeBodegaVuelo.vue'
import RangeExcesoDimensionBodegaVuelo from '../utils/tarifas/RangeExcesoDimensionBodegaVuelo.vue'
import PriceExcesoDimensionBodegaVuelo from '../utils/tarifas/PriceExcesoDimensionBodegaVuelo.vue'
import TasaDespachoBodegaVuelo from '../utils/tarifas/TasaDespachoBodegaVuelo.vue'
import AplicaBodegaVuelo from '../utils/tarifas/AplicaBodegaVuelo.vue'
import Suplementos from '../utils/Suplementos.vue'

export default {
  components: {
    AppCardCode,

    SelectClaseVuelo,
    SelectTarifaVuelo,
    CupoClaseVuelo,
    PriceAdultClaseVuelo,
    PriceChildClaseVuelo,
    PriceInfClaseVuelo,
    SelectDimensionPersonalVuelo,
    SelectDimensionCabinaVuelo,
    SelectPesoCabinaVuelo,
    SelectPesoPersonalVuelo,
    PriceCabinaVuelo,
    PricePersonalVuelo,
    AplicaPersonalVuelo,
    AplicaCabinaVuelo,
    SelectDimensionBodegaVuelo,
    SelectPesoBodegaVuelo,
    PriceMaletaBodegaVuelo,
    RangeExcesoEquipajeBodegaVuelo,
    PriceExcesoEquipajeBodegaVuelo,
    RangeExcesoDimensionBodegaVuelo,
    PriceExcesoDimensionBodegaVuelo,
    TasaDespachoBodegaVuelo,
    AplicaBodegaVuelo,
    Suplementos,

    // ClaseVuelo,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiAirplaneLanding,
        mdiCity,
        mdiChevronUpCircle,
        mdiMenuUp,
        mdiChevronDownCircle,
        mdiMenuDown,
        mdiAirplane,
        mdiInformation,
        mdiChevronLeft,
        mdiChevronRight,
        mdiInformationOutline,
        mdiAlertOutline,
      },
      hideTarifas: false,
      hidePersonal: false,
      hideCabina: false,
      hideBodega: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      timezone: '',
      aeronaves: [],
      model: {},
      aerolineas: [],
      itemsAerolinea: [],
      searchAerolinea: null,
      loading: false,
      frecuenciaFlight: [],
      horario: {},
      flights: [],
      flightsList: [],
      flightsSelect: [],
      searchFlight: null,
      flightDetail: null,
      airline: null,
      radioGroup: -1,
      radioFrecuencia: -1,
      indexFrecuenciaContrate: null,
      semanas: [],
      modelSemanas: [],
      allowedFrecuencia: [],
      allowedDatesFligth: [],
      dates: [],
      idVuelo: null,
      claseTarifa: null,
      posTarifaEdit: null,
      clasesTarifaContrate: [],
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      tarifasAll: [],
      clasesAll: [],
      tarifasEdit: [],
      dateCalendar: this.$moment(new Date()).format('D to MMMM'),
      date: this.$moment(new Date()).format('YYYY-MM-DD'),
      isDialogVisibleCupos: false,
      politicasTarifarias: [],
      categCondicionesGenerales: [],
      condicionesGenerales: [],
      condicionesGenerales_id: [],
      suplementos: [],
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      isLoadingSuplementos: true,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('flight-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      suplementoFlight: state => state.app.suplementoFlight,
      suplementosContrateFlight: state => state.app.suplementosContrateFlight,
    }),
  },
  watch: {
    searchAerolinea(val) {
      this.filterAerolinea(val.toLowerCase())
    },
    searchFlight(val) {
      if (val.length >= 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterFlights(val.toUpperCase())
      } else {
        this.flightsSelect = []
      }
    },
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },

  /* mounted() {
    const arr = ['2022-07-18', '2022-07-19', '2022-07-20', '2022-07-21', '2022-07-22']
    arr.forEach(element => {
      this.events.push({
        name: '500',
        symbol: '$ 500',
        start: element,
        end: element,
        color: 'primary',
        timed: false,
        edit: true,
      })
      this.events.push({
        name: '20',
        symbol: '20 asientos',
        start: element,
        end: element,
        color: 'info',
        timed: false,
        edit: false,
      })
    })
  }, */
  created() {
    if (sessionStorage.getItem('flight-contrate-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
    this.getFlightDetails()

    // this.getAerolinea()
    this.getAerolineas()
    this.getClases()
    this.getTarifas()

    this.getFlights()

    // this.getItem()

    this.semanas.push('D')
    this.semanas.push('L')
    this.semanas.push('M')
    this.semanas.push('X')
    this.semanas.push('J')
    this.semanas.push('V')
    this.semanas.push('S')

    this.getProveedores()

    this.getSuplementos()

    this.getCategCondiciones()
    this.getCondiciones()
  },
  methods: {
    ...mapMutations([
      'setOnlyShow',
      'initClaseVuelo',
      'adicionarClaseVuelo',
      'deleteClaseVuelo',
      'updateClaseVuelo',
      'adicionarMaletaCabina',
      'deleteMaletaBodegaVuelo',
      'updateSuplementoContrateFlight',
    ]),
    filterAerolinea(v) {
      this.itemsAerolinea = []
      if (v === '') {
        this.itemsAerolinea = []
      } else {
        this.itemsAerolinea = this.aerolineas.filter(e => e.name.toLowerCase())
      }
    },
    filterFlights(v) {
      this.flightsSelect = []
      if (v === '') {
        this.flightsSelect = []
      } else {
        this.flightsSelect = this.flights.filter(e => e)
      }
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },

    getItem() {
      if (sessionStorage.getItem('flight-contrate-id') !== null) {
        const id = sessionStorage.getItem('flight-contrate-id')
        this.idVuelo = id
        this.axios
          .get(`contrate_flights/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.suplementos !== null) {
              this.updateSuplementoContrateFlight(this.model.suplementos)
            } else {
              this.updateSuplementoContrateFlight([])
            }

            if (this.model.conditions !== null) {
              this.condicionesGenerales_id = this.model.conditions
            } else {
              this.condicionesGenerales_id = []
            }

            // BUSCAR LA TARIFA
            if (sessionStorage.getItem('flight-contrate-pos-tarifa') !== null) {
              // EDITAR TARIFA
              if (sessionStorage.getItem('flight-contrate-id') !== null) {
                if (this.model.data_clases !== null) {
                  const posTarifa = sessionStorage.getItem('flight-contrate-pos-tarifa')
                  this.radioGroup = this.model.data_clases[posTarifa].frecuencia.tab
                  this.dates = this.model.data_clases[posTarifa].frecuencia.dates
                  this.modelSemanas = this.model.data_clases[posTarifa].frecuencia.modelSemanas

                  this.updateClaseVuelo(this.model.data_clases[posTarifa].tarifas)
                } else {
                  this.updateClaseVuelo([])
                }
              }
              // eslint-disable-next-line no-empty
            } else {
              this.frecuenciaFlight = this.model.frecuencias
            }

            /* if (this.model.data_clases !== null) {
            this.updateClaseVuelo(this.model.data_clases)
          } else {
            this.updateClaseVuelo([])
          } */

            this.axios
              .get(`flight/${this.model.flight_id}`, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(resFlig => {
                this.flightDetail = resFlig.data.data.data
                this.airline = this.flightDetail.aerolinea
              })
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.getPoliticasTarifarias()
          })
      } else {
        this.updateClaseVuelo([])
        this.initClaseVuelo()

        this.updateSuplementoContrateFlight([])
      }
    },
    getPoliticasTarifarias() {
      this.axios
        .get('nom_politica_tarifaria_flights?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(resp => {
          // eslint-disable-next-line no-empty
          if (resp.data.success === false) {
          } else {
            this.politicasTarifarias = resp.data.data.filter(e => e.aerolinea_id === this.model.flight.aerolinea_id)
          }
        })
    },
    getSuplementos() {
      this.axios
        .get('nom_suplemento_contrate/flights?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.suplementos = res.data.data
          }
        })
    },
    getCategCondiciones() {
      this.axios
        .get('nom_categories_conditions?for_fligth=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categCondicionesGenerales = res.data.data
          }
        })
    },
    getCondiciones() {
      this.axios
        .get('nom_conditions_generals?for_fligth=1&per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.condicionesGenerales = res.data.data
          }
        })
    },
    // eslint-disable-next-line consistent-return
    allowedDates(val) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.allowedDatesFligth.length; i++) {
        if (this.allowedDatesFligth[i] === val) {
          return val
        }
      }
    },

    getFlights() {
      this.axios
        .get('contrate_flights/list', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.flightsList = res.data.data

          // result.forEach(element => {
          //   // eslint-disable-next-line no-nested-ternary
          //   // this.flights.push({ id: element.id, name: `${element.numero_de_vuelo} - ${element.recurso.nameComodin}` })
          //   this.flights.push({ id: element.id, name: element.numero_de_vuelo })
          // })

          // console.log(this.flightsList)
        })
        .finally(() => this.getItem())
    },

    getFlightDetails() {
      this.flightDetail = null
      if (sessionStorage.getItem('flight-numero-vuelo') !== null) {
        this.axios
          .get(`flight/by/${sessionStorage.getItem('flight-numero-vuelo')}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.flightDetail = res.data.data.data
            if (this.flightDetail.allowedDates.length > 0 && sessionStorage.getItem('flight-contrate-id') === null) {
              this.dates.push(this.flightDetail.allowedDates[0])
              setTimeout(() => {
                this.dates = []
              }, 150)
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      }
    },
    getAerolinea() {
      if (sessionStorage.getItem('flight-aerolinea-id') !== null) {
        const id = sessionStorage.getItem('flight-aerolinea-id')
        this.axios
          .get(`nom_aerolines/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.airline = res.data.data.data
          })
      }
    },
    getAerolineas() {
      this.axios
        .get(`nom_aerolines?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          result.forEach(element => {
            if (element.numero_vuelos_validos.length > 0) {
              this.aerolineas.push(element)
            }
          })
        })
    },
    getProveedores() {
      this.axios
        .get('afiliados/byproduct/vuelos', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    setProveedor() {
      if (this.model.proveedor_id) {
        this.getCategCondiciones()

        const mod = this.proveedores.filter(a => a.id === this.model.proveedor_id)[0]
        const year = this.$moment(new Date()).format('Y')
        this.model.identificador = `${mod.name_comercial}-${year}-${Math.random()
          .toString(36)
          .slice(-3)
          .toUpperCase()}`
      } else {
        this.categCondicionesGenerales = []
        this.model.identificador = null
      }
    },

    getClases() {
      this.axios
        .get('nom_clase_flights?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          result.forEach(element => {
            this.clasesAll.push({
              id: element.id,
              name: element.name,
              code: element.code,
            })
          })
        })
    },
    getTarifas() {
      this.axios
        .get('nom_tarifa_flights?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          result.forEach(element => {
            this.tarifasAll.push({
              id: element.id,
              name: element.name,
              clase_id: element.clase_id,
              aerolinea_id: element.aerolinea_id,
            })
          })
        })
    },

    resetRadios() {
      if (this.radioGroup === 0) {
        this.dates = this.flightDetail.allowedDates
        this.modelSemanas = this.flightDetail.allowedFrecuencia
      } else if (this.radioGroup === 1) {
        this.dates = []
        this.modelSemanas = []
      } else if (this.radioGroup === 2) {
        this.modelSemanas = []
        this.dates = []
      }
    },

    editDates() {
      this.dates = []
      this.modelSemanas.forEach(elementSemana => {
        this.flightDetail.allowedDates.forEach(elementFecha => {
          if (elementSemana === new Date(elementFecha).getDay() + 1) {
            this.dates.push(elementFecha)

            // para w pinchara el domin go
          } else if (elementSemana === 0) {
            if (new Date(elementFecha).getDay() === 6) {
              this.dates.push(elementFecha)
            }
          }
        })
      })
    },

    save() {
      if (
        this.$store.state.app.clasesVuelos.length > 0
        && this.radioFrecuencia !== -1
        && this.radioGroup !== -1
        && this.model.proveedor_id
        && this.model.identificador
      ) {
        this.loading = true

        const jsonF = {
          tab_frecuencia: this.radioFrecuencia,
          tab: this.radioGroup,
          modelSemanas: this.modelSemanas,
          dates: this.dates,
        }

        const jsonClaseDate = []
        this.dates.forEach(date => {
          this.$store.state.app.clasesVuelos.forEach(claseDate => {
            jsonClaseDate.push({
              date,
              class_id: claseDate.class_id,
              politica_tarifaria_id: this.model.politica_tarifaria_id,
              tarifa_id: claseDate.tarifa_id,
              cupo: claseDate.cupo,
              cupo_reservado: 0,
              cupo_disponible: claseDate.cupo,
              price_adult: claseDate.price_adult,
              price_child: claseDate.price_child,
              price_infant: claseDate.price_infant,

              equipaje_personal: {
                dimensiones: claseDate.equipaje_personal.dimensiones,
                peso: claseDate.equipaje_personal.peso,
                precio: claseDate.equipaje_personal.precio,
                incluido: claseDate.equipaje_personal.incluido,
                permitido: claseDate.equipaje_personal.permitido,
                aplica_adult: claseDate.equipaje_personal.aplica_adult,
                aplica_child: claseDate.equipaje_personal.aplica_child,
                aplica_inf: claseDate.equipaje_personal.aplica_inf,
              },

              equipaje_cabina: {
                dimensiones: claseDate.equipaje_cabina.dimensiones,
                peso: claseDate.equipaje_cabina.peso,
                precio: claseDate.equipaje_cabina.precio,
                incluido: claseDate.equipaje_cabina.incluido,
                permitido: claseDate.equipaje_cabina.permitido,
                aplica_adult: claseDate.equipaje_cabina.aplica_adult,
                aplica_child: claseDate.equipaje_cabina.aplica_child,
                aplica_inf: claseDate.equipaje_cabina.aplica_inf,
              },

              equipaje_bodega: {
                dimensiones: claseDate.equipaje_bodega.dimensiones,
                peso: claseDate.equipaje_bodega.peso,
                maletas: claseDate.equipaje_bodega.maletas,
                max_exceso_equipaje: claseDate.equipaje_bodega.max_exceso_equipaje,
                exceso_equipaje: claseDate.equipaje_bodega.exceso_equipaje,
                tasa_despacho: claseDate.equipaje_bodega.tasa_despacho,
                aplica_adult: claseDate.equipaje_bodega.aplica_adult,
                aplica_child: claseDate.equipaje_bodega.aplica_child,
                aplica_inf: claseDate.equipaje_bodega.aplica_inf,
              },
            })
          })
        })

        const json = {
          flight_id: this.flightDetail.id,
          proveedor_id: this.model.proveedor_id,
          identificador: this.model.identificador,
          perfil_slug: sessionStorage.getItem('perfil'),
          data_frecuencia: jsonF,
          data_clases: jsonClaseDate,
          suplementos: this.suplementosContrateFlight,
          conditions: this.condicionesGenerales_id,

          // data_clases: this.$store.state.app.clasesVuelos,
        }

        // console.log(json)

        if (sessionStorage.getItem('flight-contrate-id') === null) {
          this.axios
            .post('contrate_flights', json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.dupliqueFrecuencia'))
                } else if (response.data.data.status === 403) {
                  this.$toast.error(this.$t('msg.existContrate', { identificador: this.model.identificador }))
                }
              } else {
                // console.log(response)
                sessionStorage.setItem('flight-contrate-id', response.data.data.data.id)
                sessionStorage.setItem('flight-contrate-tarifa-edit', true)

                // this.idVuelo = response.data.data.data.id
                // this.frecuenciaFlight = response.data.data.data.frecuencias
                this.getItem()

                this.resetFlight()

                this.$toast.success(this.$t('msg.infoSuccess'))

                // sessionStorage.removeItem('flight-contrate-id')
                // this.$router.push({ name: 'flights-contrate-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          if (sessionStorage.getItem('flight-contrate-tarifa-edit') !== null) {
            // eslint-disable-next-line radix
            json.tarifa_pos = parseInt(sessionStorage.getItem('flight-contrate-pos-tarifa'))
          }
          if (this.posTarifaEdit !== null) {
            json.tarifa_pos = this.posTarifaEdit
          }
          this.axios
            .put(`contrate_flights/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.dupliqueFrecuencia'))
                }
              } else {
                // sessionStorage.removeItem('flight-contrate-id')
                // this.$router.push({ name: 'flights-contrate-list' })
                this.getItem()
                this.resetFlight()

                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    changeAerolinea() {
      this.updateClaseVuelo([])
      if (this.model.aerolinea_id) {
        this.flightDetail = null
        this.airline = null

        const air = this.aerolineas.filter(a => a.id === this.model.aerolinea_id)[0]
        this.model.numero_de_vuelo = `${air.slug} `
        this.$refs.numero_de_vuelo.focus()

        this.flights = air.numero_vuelos_validos

        // POLITICAS TARIFARIAS
        this.axios
          .get('nom_politica_tarifaria_flights?per_page=100000', {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // eslint-disable-next-line no-empty
            if (res.data.success === false) {
            } else {
              this.politicasTarifarias = res.data.data.filter(e => e.aerolinea_id === this.model.aerolinea_id)
            }
          })
      } else {
        this.flights = []
        this.flightDetail = null
        this.airline = null
      }
    },
    changeVuelo() {
      // eslint-disable-next-line camelcase
      const flight_id = this.flightsList.filter(f => f.numero_de_vuelo === this.model.numero_de_vuelo)[0]

      // console.log(this.model.numero_de_vuelo)
      // console.log(flight_id)

      // eslint-disable-next-line camelcase
      if (this.model.numero_de_vuelo && flight_id) {
        this.axios
          // eslint-disable-next-line camelcase
          .get(`flight/${flight_id.id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(resFlig => {
            this.flightDetail = resFlig.data.data.data
            this.allowedFrecuencia = this.flightDetail.allowedFrecuencia
            this.allowedDatesFligth = this.flightDetail.allowedDates
            this.airline = this.flightDetail.aerolinea

            // console.log(this.flightDetail)
          })
      } else {
        this.flightDetail = null
        this.airline = null
      }
    },
    nameFrecuencia(frecuencia) {
      // console.log(frecuencia)
      let name = ''
      frecuencia.forEach(element => {
        if (element === 0) {
          name += 'D, '
        } else if (element === 1) {
          name += 'L, '
        } else if (element === 2) {
          name += 'M, '
        } else if (element === 3) {
          name += 'X, '
        } else if (element === 4) {
          name += 'J, '
        } else if (element === 5) {
          name += 'V, '
        } else if (element === 6) {
          name += 'S, '
        }
      })

      return name
    },

    viewDay({ date }) {
      this.focus = date

      const taris = this.model.data_clases[this.posTarifaEdit].tarifas
      this.tarifasEdit = []
      taris.forEach(tarifa => {
        if (date === tarifa.date) {
          this.tarifasEdit.push({
            class_id: tarifa.class_id,
            clase: this.clasesAll.filter(c => c.id === tarifa.class_id)[0].name,
            cupo: parseFloat(tarifa.cupo),
            date: tarifa.date,
            price_adult: parseFloat(tarifa.price_adult),
            price_child: parseFloat(tarifa.price_child),
            price_infant: parseFloat(tarifa.price_infant),
            tarifa_id: tarifa.tarifa_id,
            tarifa: this.tarifasAll.filter(c => c.id === tarifa.tarifa_id)[0].name,
          })
        }
      })

      this.dateCalendar = this.$moment(date).format('D, MMM')
      this.date = this.$moment(date).format('YYYY-MM-DD')
      this.isDialogVisibleCupos = true
    },
    updateCupos() {
      this.events = []

      // console.log(this.tarifasEdit)
      // this.model.data_clases[this.posTarifaEdit].tarifas
      const json = {
        tarifas: this.tarifasEdit,
        pos_tarifa: this.posTarifaEdit,
        date: this.date,
        id_contrate: this.model.id,
      }

      // console.log(json)

      this.loading = true

      this.axios
        .post('contrate_flights/cupos', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // console.log(response)
          if (response.data.success === false) {
            if (response.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else if (response.data.data.status === 402) {
              this.$toast.error(this.$t('msg.dupliqueFrecuencia'))
            }
          } else {
            // console.log(response)

            this.getItem()

            setTimeout(() => {
              this.changeClase()
            }, 500)
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false
          this.isDialogVisibleCupos = false
        })
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        // eslint-disable-next-line no-return-assign
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: '$700',
          start: first,
          end: second,
          color: 'black',
          timed: !allDay,
        })
      }

      this.events = events
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

    setRadioFrecuency(frecuencia, index) {
      // console.log(this.radioFrecuencia)
      // console.log(frecuencia)
      this.radioFrecuencia = index
      this.allowedFrecuencia = frecuencia.frecuencia
      this.allowedDatesFligth = frecuencia.allowedDates
    },

    resetFlight() {
      this.radioGroup = -1
      this.radioFrecuencia = -1
      this.allowedFrecuencia = []
      this.allowedDatesFligth = []
      this.dates = []
      this.indexFrecuenciaContrate = null

      this.updateClaseVuelo([])
      this.initClaseVuelo()
    },
    setIndexFrecuenciaContrate(codeTarifa) {
      this.events = []
      this.claseTarifa = null
      if (codeTarifa !== this.indexFrecuenciaContrate) {
        this.indexFrecuenciaContrate = codeTarifa
        this.frecuenciaFlight.forEach(frec => {
          if (frec.code_tarifa === codeTarifa) {
            this.clasesTarifaContrate = frec.tarifas

            // console.log(this.model.data_clases[frec.pos].frecuencia.tab_frecuencia)

            // console.log(this.model.data_clases[frec.pos])
            this.posTarifaEdit = frec.pos
            this.radioFrecuencia = this.model.data_clases[frec.pos].frecuencia.tab_frecuencia
            this.radioGroup = this.model.data_clases[frec.pos].frecuencia.tab_frecuencia
            this.modelSemanas = this.model.data_clases[frec.pos].frecuencia.modelSemanas
            this.dates = this.model.data_clases[frec.pos].frecuencia.dates

            const ttt = this.model.data_clases[frec.pos].tarifas
            let tttAr = null
            const tariAr = []
            ttt.forEach(tarifa => {
              if (tttAr === null) {
                tttAr = tarifa.date
                tariAr.push(tarifa)
              } else if (tttAr === tarifa.date) {
                tariAr.push(tarifa)
              }
            })

            // console.log(tariAr)
            this.updateClaseVuelo(tariAr)
            this.model.politica_tarifaria_id = tariAr[0].politica_tarifaria_id

            this.allowedFrecuencia = this.flightDetail.frecuencia[
              this.model.data_clases[frec.pos].frecuencia.tab_frecuencia
            ].frecuencia
            this.allowedDatesFligth = this.flightDetail.frecuencia[
              this.model.data_clases[frec.pos].frecuencia.tab_frecuencia
            ].allowedDates

            // POLITICAS TARIFARIAS
            /* this.axios.get('nom_politica_tarifaria_flights?per_page=100000', {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            }).then(resp => {
              // eslint-disable-next-line no-empty
              if (resp.data.success === false) { } else {
                this.politicasTarifarias = resp.data.data.filter(e => e.aerolinea_id === this.model.flight.aerolinea_id)
                console.log(this.politicasTarifarias)
              }
            }) */
          }
        })
      } else {
        this.posTarifaEdit = null
        this.indexFrecuenciaContrate = null
        this.clasesTarifaContrate = []
        this.radioFrecuencia = -1
        this.radioGroup = -1
        this.modelSemanas = []
        this.dates = []

        this.updateClaseVuelo([])
        this.initClaseVuelo()
      }
    },
    changeClase() {
      this.model.data_clases.forEach(clase => {
        if (clase.code_tarifa === this.indexFrecuenciaContrate) {
          // console.log(clase.tarifas)
          this.events = []
          clase.tarifas.forEach(tarifa => {
            if (tarifa.tarifa_id === this.claseTarifa) {
              this.events.push({
                name: tarifa.price_adult,
                symbol: `$ ${tarifa.price_adult}`,
                start: tarifa.date,
                end: tarifa.date,
                color: 'primary',
                timed: false,
                edit: true,
                type: 'price',
              })
              this.events.push({
                name: tarifa.cupo,
                symbol: `${tarifa.cupo} asientos`,
                start: tarifa.date,
                end: tarifa.date,
                color: 'info',
                timed: false,
                edit: true,
                type: 'seat',
              })
            }
          })
        }
      })

      /* this.events.push({
        name: '500',
        symbol: '$ 500',
        start: element,
        end: element,
        color: 'primary',
        timed: false,
        edit: true,
      })
      this.events.push({
        name: '20',
        symbol: '20 asientos',
        start: element,
        end: element,
        color: 'info',
        timed: false,
        edit: false,
      }) */
    },

    setPoliticaTarifaria() {
      const tarifaSelect = this.politicasTarifarias.filter(e => e.id === this.model.politica_tarifaria_id)
      if (tarifaSelect.length > 0) {
        this.updateClaseVuelo(tarifaSelect[0].data_clases)
      }
    },
  },
}
</script>

<!--<style scoped>
/*::v-deep .v-data-table > .v-data-table__wrapper > table {
    width: 150%;
    border-spacing: 0;
  }*/

.rb-container {
  width: 100%;
  margin: auto;
  display: block;
  position: relative;
}

.rb-container ul.rb {
  margin: 10px 0;
  padding: 0;
  display: inline-block;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  margin-left: 0;
  min-height: 50px;
  border-left: 1px dashed #716f60;
  padding: 0 0 50px 30px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -16px;
  top: 0px;
  content: ' ';
  border-radius: 500%;
  background: #007bc3;
  height: 30px;
  width: 30px;
}

.rb-container ul.rb li:hover::before {
  border-color: #232931;
  transition: all 1000ms ease-in-out;
}
</style> -->

<style scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
::v-deep .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: center;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
